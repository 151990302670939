<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="Aboutus" style="width:100vw">
    <div class="about_button__wrap">
      <div class="about_button__item">
        <button class="btn dal-btn"
                :class="{ active: active === 0 }"
                @click="active = 0">
          企业简介
        </button>
      </div>
      <div class="about_button__item">
        <button class="btn dal-btn"
                :class="{ active: active === 1 }"
                @click="active = 1">
          加入我们
        </button>
      </div>
    </div>

    <div class="about__container">
      <div class="company_profiles__wrap"
           v-if="active === 0">
        <el-card class="box-card">
          <h4>公司简介</h4>
          <div class="content">
            <div class="content-left">
              <p>
                武汉数字智能信息科技有限公司 (以下简称“数字智能”)，
                是一家为企业客户提供软件产品和系统快速定制开发的技术服务企业。
              </p>
              <p>
                通过完全自研、拥有自主知识产权的“一体化业务开发平台”和“TaaS on Daline”的技术服务模式，数字智能已与多家金融机构、中小企业建立深度合作，以APP、小程序、WEB端、数据大屏等多种产品形态为依托，为企业完成数字化转型点燃引擎，为运营团队建设私域流量提质增效。
              </p>
              <p>
                数字智能自创立起，坚持以客户为中心，打磨产品，持续创新，在大数据、人工智能、金融科技方面皆有技术沉淀，下一步将继续坚守使命与目标，让我们的客户可以用市场上最低的价格，获得最好的技术服务；让我们的同事在同等工作强度下，可以拿到市场上最高的回报！
              </p>
            </div>
            <div class="content-right">
              <img :src="require('@/assets/company_logo.png')"
                   alt="" />
              <!-- <img :src="articleImg"
                   alt="" /> -->
            </div>
          </div>
        </el-card>
      </div>
      <div class="join_us__wrap"
           v-else>
        <h4>加入我们</h4>
        <el-card class="box-card"
                 :body-style="{ padding: 0 }">
          <el-row class="row"
                  v-for="(item, i) in positionlist"
                  :key="i">
            <el-col :span="8">
              <span @click="() => item.isOpen ? item.isOpen = false : item.isOpen = true"
                    style="cursor:pointer;"> {{ item.PositionName }} </span>
            </el-col>
            <el-col :span="6">
              <p @click="getmapLocation(item)"
                 style="cursor: pointer">
                <i class="el-icon-map-location"></i>
                <span>{{ item.location }}</span>
              </p>
            </el-col>
            <el-col :span="10">
              <div class="icon-space"
                   @click="item.isOpen ? item.isOpen = false : item.isOpen = true">
                <i class="el-icon-caret-bottom"
                   v-if="item.isOpen === false"
                   @click="item.isOpen = true"></i>
                <i class="el-icon-caret-top"
                   v-else
                   @click="item.isOpen = false"></i>
              </div>
            </el-col>
            <el-col :span="24"
                    class="info"
                    v-if="item.isOpen">
              <p class="text-grey text-small py-2">
                {{ item.type || "" }}
              </p>
              <el-row type="flex"
                      justify="space-between"
                      class="text-small text-normal py-2">
                <el-col :span="2">职位描述</el-col>
                <el-col :span="22">
                  <el-row type="flex"
                          align="middle"
                          class="fill-height">
                    <div class="divider"></div>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="ql-editor"
                      v-html="item.requirements"
                      contenteditable="false" id="ql-editor"
                      ></el-row>
              <el-col>
                <el-row type="flex"
                        justify="center">
                  <button class="dal-btn normal m-auto">
                    <a style="display:block; width: 100%; color:#fff;"
                       href="mailto:contact@daline.com.cn">申请职位</a>
                  </button>
                </el-row>

              </el-col>
            </el-col>

          </el-row>
          <!-- <el-row class="row"
                  v-for="(item, i) in positionlist"
                  :key="i">
            <el-col :span="8"   v-if="item.isOpen === false"
                 @click="item.isOpen = true" >
              {{ item.PositionName }}
            </el-col>
            <el-col :span="8"    v-else
                 @click="item.isOpen = false" >
              {{ item.PositionName }}
            </el-col>

            <el-col :span="6">
              <i class="el-icon-map-location"
                 @click="getmapLocation(item)"></i>
              <span>{{ item.location }}</span>
            </el-col>
            <el-col :span="10">
              <div class="icon-space"
                   @click="item.isOpen ? item.isOpen = false : item.isOpen = true">
                <i class="el-icon-caret-bottom"
                   v-if="item.isOpen === false"
                   @click="item.isOpen = true"></i>
                <i class="el-icon-caret-top"
                   v-else
                   @click="item.isOpen = false"></i>
              </div>
            </el-col>
            <el-col :span="24"
                    class="info"
                    v-if="item.isOpen">
              <p class="text-grey text-small py-2">
                {{ item.type || "" }}
              </p>
              <el-row type="flex"
                      justify="space-between"
                      class="text-small text-normal py-2">
                <el-col :span="2">职位描述</el-col>
                <el-col :span="22">
                  <el-row type="flex"
                          align="middle"
                          class="fill-height">
                    <div class="divider"></div>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="mt-2 text-normal"
                      v-html="item.requirements"
                      contenteditable="false"></el-row>
            </el-col>
          </el-row> -->
        </el-card>
      </div>
    </div>

    <div id="dlgfabb74601"
         v-show="vis_abb74601"
         @click.self="vis_abb74601 = false">
      <div id="abb74601">
        <div id="a9856761">
          <i class="el-icon-map-location"></i>
          洪山区光谷软件园宏泰国资创新港D8栋F座
        </div>
        <div class="map"
             :center="center">
          <baidu-map class="bmView"
                     :scroll-wheel-zoom="true"
                     :center="center"
                     :zoom="zoom"
                     ak="BO5EzBQehA6qp5vhF3GDiaGWXR4XRdQA">
            <bm-view id="bm-view"></bm-view>
            <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-scale>
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-local-search :keyword="addressKeyword"
                             :auto-viewport="true"
                             :panel=false
                             :location="locationhongshanqu"
                             :selectFirstResult=true
                             @searchcomplete="searchcomplete"
                             @infohtmlset="infohtmlset"
                             style="width: 100%; height: 80px; flex: 1"></bm-local-search>

          </baidu-map>
        </div>
      </div>
    </div>

    <div id="a28d216a">
      <dal-compfloat id="cmpa28d216a"> </dal-compfloat>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import { BaiduMap, BmScale, BmNavigation } from "vue-baidu-map";
import BmView from "vue-baidu-map/components/map/MapView.vue";
// import BmGeolocation from "vue-baidu-map/components/controls/Geolocation.vue"; //定位
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";
// import BmNavigation from "vue-baidu-map/components";
//在此注释下方添加自定义函数
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from "../components/dal-compfloat";
import {
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList,
  DALINEWEB_ARTICLECENTER_V0X0X1_getPosition,
} from "../api/dalapi.js";
export default {
  components: {
    "dal-compfloat": dal_compfloat,
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmNavigation,
    BmScale,
  },
  data () {
    return {
      active: 0,
      jobList: [
        {
          name: "资深Java开发工程师",
          location: "宏泰国资创新港",
          isOpen: true,
          type: "",
          description: `<p>岗位职责:</p>
          <p>1.负责公司Web的前端体系</p>
          <p>2.负责公司Web的前端体系eee</p>
          <p>任职资格:</p>
          <p>1.本科或以上学历，计算机相关专业，5年以上...</p>`,
        },
      ],
      articleImg: "",
      mkd_a2020a72: "",
      op_dedd89e7: [],
      val_dedd89e7: "",
      op_a0f6b56a: [],
      val_a0f6b56a: "",
      op_a58a417d: [],
      val_a58a417d: 0,
      op_a9bfc5bd: [],
      val_a9bfc5bd: 0,
      op_f1cd2404: [],
      val_f1cd2404: 0,
      op_a28f5636: [],
      val_a28f5636: 0,
      op_a318875d: [],
      val_a318875d: 0,
      op_fc1f9d4f: [],
      val_fc1f9d4f: 0,
      op_aa1325e7: [],
      val_aa1325e7: 0,
      op_a29179d0: [],
      val_a29179d0: 0,
      //在此注释下方添加自定义变量
      op_a6cb75fe: [],
      val_a6cb75fe: "",
      op_ac93325c: [],
      val_ac93325c: "",
      op_a502d7ab: [],
      val_a502d7ab: "",
      op_d356b6e5: [],
      val_d356b6e5: "",
      op_aed06c36: [],
      val_aed06c36: "",
      op_da98d756: [],
      val_da98d756: "",
      op_a77a077f: [],
      val_a77a077f: "",
      op_adce0ccd: [],
      val_adce0ccd: "",
      articleList: [],
      positionlist: [],
      addressKeyword: "",
      vis_abb74601: false,
      locationhongshanqu: '武汉市洪山区',
      address: "address",
      center: {
        lng: 114.411172,

        lat: 30.482898,
      },
      zoom: 15,
    };
  },
  mounted () {
    this.getarticlelist();
    this.getPositondata();
    // this.changeNavHeaderStyle();
  },
  computed: {},
  watch: {},
  methods: {
    searchcomplete () {
      console.log("searchcomplete");
      console.log(this.isShowInfo);
      this.isShowInfo = true
    },
    infohtmlset (data) {
      console.log(data, "poi");
      data = {
        poi: {
          title: "a"
        }
      }
    },
    changeNavHeaderStyle () {
      const header = document.querySelector("header.header");
      const logo = document.querySelector("header.header img");
      // header.style.backgroundColor = "transparent";
      // header.style.color = "#fff";
      // logo.setAttribute("src", "/logo_transparent.png");
      header.style.backgroundColor = "#fff";
      header.style.color = "#000";
      logo.setAttribute("src", "/logo_white.png");
    },
    //在此注释下方添加自定义函数或事件
    //         getClickInfo(e) {
    //   console.log(e.point.lng);
    //   console.log(e.point.lat);
    //   this.center.lng = e.point.lng;
    //   this.center.lat = e.point.lat;
    // },
    async getarticlelist () {
      let dicInput = {
        pnum: 1,
        records: 6,
        ofield: "insertTime",
        sort: "",
        sort_rules: "preview",
        ArticleType: 87,
      };
      dicInput.status = "0";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList(dicInput);
      const articleListData = JSON.parse(JSON.stringify(res.retContent));
      const articleList = JSON.parse(articleListData.retContent);
      let mkd_a2020a72 = articleList.articleList[0].articleContent;
      // this.mkd_a2020a72 = mkd_a2020a72.replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ').replace(/&nbsp;/g,'');
      this.mkd_a2020a72 = mkd_a2020a72;
      this.articleImg = articleList.articleList[0].articleImg;
      this.$nextTick(() => {
        document.querySelectorAll(".content-left > p").forEach((p) => {
          p.style.textIndent = "2em";
          p.style.marginBottom = ".5em";
        });
      });

      console.log(this.mkd_a2020a72);
    },
    async getPositondata () {
      let dicinput = {};
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getPosition(dicinput);
      let positionlist = res.content.resContent;
      positionlist = positionlist.map((p) => ({ ...p, isOpen: false }));
      this.positionlist = positionlist;
      console.log(this.positionlist);
    },
    getmapLocation () {
      this.vis_abb74601 = true;
      this.addressKeyword = "宏泰国资创新港"
    },
  },
};
</script>
<style lang="scss" scoped>
#Aboutus {
  background: #f8f8f8;
  // min-height: 100vh;
  padding-bottom: 5rem;

  .company_profiles__wrap {
    /deep/ .el-card__body {
      padding-bottom: 35px !important;
    }
  }
}
.fill-height {
  height: 100%;
}
.divider {
  width: 100%;
  height: 2px;
  transform: scaleY(0.5);
  background: #eee;
}
.text-grey {
  color: #b2bec3;
}
.text-normal {
  color: #636e72;
}
.text-mini {
  font-size: 0.5rem;
}
.text-small {
  font-size: 1rem;
}
.text-large {
  font-size: 1.5rem;
}
.title {
  position: relative;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  text-align: center;
  font: 500 2em/1.5 "PingFang SC";
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2em;
    height: 0.15em;
    background: #e74c3c;
  }
}
.about_button__wrap {
  // margin-top: 4em;
  padding: 4em 30vw;
  display: flex;
  justify-content: space-between;
  .about_button__item button {
    font-size: 1em;
    padding: 0.75em 5em;
    border-radius: 0.3em;
    background: #fff;
    color: #141620;
    border: 1px solid #141620;
    opacity: 0.5;
    &.active {
      background: #000;
      color: #fff;
      opacity: 1;
    }
  }
}

.about__container {
  padding: 0 12vw;
  // margin-top: 1em;
  .company_profiles__wrap {
    .box-card {
      padding: 0 2em;
      h4 {
        @extend .title;
      }
      .content {
        color: #acacac;
        font-size: 1.5em;
        display: flex;
        &-left,
        &-right {
          flex: 1;
        }
        &-right {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 300px;
          }
        }
      }
    }
  }
  .join_us__wrap {
    // padding: 0 10vw;
    h4 {
      @extend .title;
      margin-bottom: 1.5em;
    }
    .box-card {
      font-size: 1.25em;
      .row {
        padding: 1.5em;
        .el-icon-map-location {
          color: #b2bec3;
          margin-right: 0.5em;
        }
        .el-icon-caret-bottom,
        .el-icon-caret-top {
          font-size: 1.5em;
        }
      }
      .row + .row {
        border-top: 1px solid #dfe6e9;
      }
    }
  }
}
.el-collapse-item__header {
  font-size: 20px;
}
.el-collapse-item__content {
  font-size: 16px;
}
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#f835a81c {
  width: 100vw;
  height: 50vh;
  position: relative;
}
#f835a81c {
  background-image: url("../assets/img-d776e346.png");
  background-size: cover;
}
#ac819b8d {
  padding: 0 15vw;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#ac819b8d {
  vertical-align: top;
}
#ac819b8d /deep/ .el-tabs__item {
  font-size: 30px;
  font-family: PingFang SC;
}

#a2020a72 {
  min-height: 30vh;
}
#a2020a72 {
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.16);
}
#a058fb2e {
  padding: 0 1em;
}
#a058fb2e {
  background-color: rgba(255, 255, 255, 0.011927500367164612);
  border-radius: 0px 0px 0px 0px;
}
#abf18ae8 {
  padding-top: 50px;
}
#abf18ae8 {
  text-align: center;
  color: #1c1c1c;
  font-size: 45px;
  font-family: PingFang SC;
}

#fca02012 {
  margin-top: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.16);
}
#a348fdc8 {
  padding: 2em;
  position: relative;
}
#f4f61adb {
  margin-top: 2em;
  padding: 0 2em;
  display: flex;
}
#a56c6d07,
#e790abb5,
#dedd89e7 {
  flex: 1;
}
#a56c6d07 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#ae437b03 {
  width: 40px;
  height: 45px;
}
#ae437b03 {
  vertical-align: middle;
}
#e790abb5 {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#e790abb5 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#f4f61adb .el-select {
  width: 49px;
  margin-left: 531px;
  padding-top: 12px;
  padding-bottom: 13px;
}
#e5f295d3 {
  width: 1219px;
  height: 3px;
  margin-top: 51px;
}
#e5f295d3 {
  vertical-align: middle;
}
#ae20af64 {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#ae52a0f9 {
  width: 1119px;
  position: relative;
  padding-left: 50px;
}
#ac23b9ba {
  width: 277px;
  height: 38px;
  padding-top: 4px;
  padding-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
}
#ac23b9ba {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a9b19189 {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#a9b19189 {
  vertical-align: middle;
}
#f70fc351 {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#f70fc351 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#ae52a0f9 .el-select {
  width: 49px;
  margin-left: 530px;
  padding-top: 13px;
  padding-bottom: 13px;
}
#a7a2a501 {
  width: 1219px;
  height: 3px;
  margin-top: 50px;
}
#a7a2a501 {
  vertical-align: middle;
}
#ae6bc0be {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#a58366c0 {
  width: 1119px;
  position: relative;
  padding-left: 50px;
}
#a0f15f3f {
  width: 277px;
  height: 38px;
  padding-top: 4px;
  padding-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
}
#a0f15f3f {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#d8a29463 {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#d8a29463 {
  vertical-align: middle;
}
#e7f4515a {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#e7f4515a {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a58366c0 .el-select {
  width: 49px;
  margin-left: 530px;
  padding-top: 13px;
  padding-bottom: 13px;
}
#a53757d7 {
  width: 1219px;
  height: 3px;
  margin-top: 50px;
}
#a53757d7 {
  vertical-align: middle;
}
#ec2b07cc {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#bf1362ad {
  width: 1120px;
  position: relative;
  padding-left: 50px;
}
#a05d9f60 {
  width: 277px;
  height: 38px;
  padding-top: 3px;
  padding-bottom: 4px;
  display: inline-block;
  vertical-align: middle;
}
#a05d9f60 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a2e58a6c {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#a2e58a6c {
  vertical-align: middle;
}
#a7cd3a03 {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#a7cd3a03 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#bf1362ad .el-select {
  width: 49px;
  margin-left: 531px;
  padding-top: 12px;
  padding-bottom: 13px;
}
#ae6e17f0 {
  width: 1219px;
  height: 3px;
  margin-top: 51px;
}
#ae6e17f0 {
  vertical-align: middle;
}
#eab3d155 {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#abfcaea3 {
  width: 1119px;
  position: relative;
  padding-left: 50px;
}
#adbf22d3 {
  width: 277px;
  height: 38px;
  padding-top: 4px;
  padding-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
}
#adbf22d3 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a16ce24b {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#a16ce24b {
  vertical-align: middle;
}
#d7fc5277 {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#d7fc5277 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#abfcaea3 .el-select {
  width: 49px;
  margin-left: 530px;
  padding-top: 13px;
  padding-bottom: 13px;
}
#ad4dac4d {
  width: 1219px;
  height: 3px;
  margin-top: 50px;
}
#ad4dac4d {
  vertical-align: middle;
}
#af83eacb {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#dfd38dbc {
  width: 1119px;
  position: relative;
  padding-left: 50px;
}
#bc29b72a {
  width: 277px;
  height: 38px;
  padding-top: 4px;
  padding-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
}
#bc29b72a {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a48f372d {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#a48f372d {
  vertical-align: middle;
}
#a08f0312 {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#a08f0312 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#dfd38dbc .el-select {
  width: 49px;
  margin-left: 530px;
  padding-top: 13px;
  padding-bottom: 13px;
}
#a9052fdd {
  width: 1219px;
  height: 3px;
  margin-top: 50px;
}
#a9052fdd {
  vertical-align: middle;
}
#cf7f4bb1 {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#a19153b2 {
  width: 1120px;
  position: relative;
  padding-left: 50px;
}
#ada2c7af {
  width: 277px;
  height: 38px;
  padding-top: 3px;
  padding-bottom: 4px;
  display: inline-block;
  vertical-align: middle;
}
#ada2c7af {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#ae61629f {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#ae61629f {
  vertical-align: middle;
}
#c3ccbaf7 {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#c3ccbaf7 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a19153b2 .el-select {
  width: 49px;
  margin-left: 531px;
  padding-top: 12px;
  padding-bottom: 13px;
}
#a6c72191 {
  width: 1219px;
  height: 3px;
  margin-top: 51px;
}
#a6c72191 {
  vertical-align: middle;
}
#a670140b {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#a24cfd83 {
  width: 1119px;
  position: relative;
  padding-left: 50px;
}
#ab9d9b0f {
  width: 277px;
  height: 38px;
  padding-top: 4px;
  padding-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
}
#ab9d9b0f {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#ebd0b249 {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#ebd0b249 {
  vertical-align: middle;
}
#a14fe2a1 {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#a14fe2a1 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a24cfd83 .el-select {
  width: 49px;
  margin-left: 530px;
  padding-top: 13px;
  padding-bottom: 13px;
}
#a43373b4 {
  width: 1219px;
  height: 3px;
  margin-top: 50px;
}
#a43373b4 {
  vertical-align: middle;
}
#a6f4dbef {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#a8d228dc {
  width: 1119px;
  position: relative;
  padding-left: 50px;
}
#aa9ae692 {
  width: 277px;
  height: 38px;
  padding-top: 4px;
  padding-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
}
#aa9ae692 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#dc8f30ac {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#dc8f30ac {
  vertical-align: middle;
}
#a822c03f {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#a822c03f {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a8d228dc .el-select {
  width: 49px;
  margin-left: 530px;
  padding-top: 13px;
  padding-bottom: 13px;
}
#aebd94d7 {
  width: 1219px;
  height: 3px;
  margin-top: 50px;
}
#aebd94d7 {
  vertical-align: middle;
}
#ae75f73b {
  width: 1219px;
  position: relative;
  margin-top: 50px;
}
#a0b06e10 {
  width: 1119px;
  position: relative;
  padding-left: 50px;
}
#aad6454e {
  width: 277px;
  height: 38px;
  padding-top: 4px;
  padding-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
}
#aad6454e {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#aa98bc57 {
  width: 40px;
  height: 45px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
#aa98bc57 {
  vertical-align: middle;
}
#b02a4188 {
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}
#b02a4188 {
  text-align: left;
  color: #2b2b2b;
  font-size: 30px;
  font-family: PingFang SC;
}
#a0b06e10 .el-select {
  width: 49px;
  margin-left: 530px;
  padding-top: 13px;
  padding-bottom: 13px;
}
#ad68cc67 {
  width: 1219px;
  height: 3px;
  margin-top: 50px;
}
#ad68cc67 {
  vertical-align: middle;
}
#dc51dcd8 {
  width: 302px;
  margin-top: 60px;
  padding-left: 978px;
  padding-bottom: 17px;
}
#a28d216a {
  width: 150px;
  position: fixed;
  z-index: 50;
  top: 1481px;
  left: 1740px;
}
#a28d216a {
  background-color: rgb(20, 22, 32);
  border-radius: 5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
#dlgfabb74601 {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1010;
}
#abb74601 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -93px 0 0 -167px;
  background-color: #fff;
  z-index: 1003;
  box-shadow: 0 0 14px gray;
  margin: -250px 0px 0px -330px;
  // position: absolute;
  // top: 248px;
  // left: 610px;
  // width: 700px;
  // height: 613px;
  // border-radius: 10px 10px 10px 10px;
  background: #fff;
}

#a9856761 {
  display: block;
  background-position: 15px -420px;
  padding-left: 25px;
  color: #62687a;
  padding: 13px 15px 11px 8px;
  font-size: 15px;
  border-bottom: 1px #ecedef solid;
  font-family: PingFang SC;
  .el-icon-map-location {
    color: #b2bec3;
    margin-right: 0.5em;
  }
}
#ab466a5b {
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2);
  margin: 0 18.285vw;
}
.icon-space {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.map {
  max-height: 100%;
}
.bmView {
  width: 660px;
  height: 501px;
  margin: 0;
}
#bm-view {
  width: 100%;
  height: 100%;
  flex: 1;
}
.content-left > p {
  text-indent: 2em;
  margin-bottom: 0.5em;
  word-break: break-all;
}
.Email {
  left: 50%;
  position: relative;
}
</style>
